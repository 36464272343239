<template>
    <div v-if="dataSource" class="p-relative">
        
        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        >

        </FilterPanel>

        <component
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

    </div>
</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import _ from 'lodash';

import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import EventsList from './Components/EventsList.vue';
import EventsCalendar from './Components/EventsCalendar.vue';

export default {
    name: "EventsManagmentProxy",
    components:  {
        Toolbar,
        FilterPanel,
        EventsList,
        EventsCalendar
    },
    computed: {
        collection: {
            get: function() {
                return this.$store.getters['eventManagement/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('eventManagement/SET_COLLECTION', newValue);
                if (newValue == 'EventsList')
                    this.loadFilters();
            }
        },
        status: {
            get: function() {
                return this.$store.getters['eventManagement/getStatus']
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('eventManagement/PUSH_STATUS', { collection: this.collection, value: newValue });

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                    this.loadFilters();
                }
            }
        },
        search: {
            get: function() {
                return this.$store.getters['eventManagement/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }                
                this.$store.commit('eventManagement/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null,
        }
    },
    methods: {
        onToolbarClick(_, btn) {
            this[btn.Action](btn);
        },
        async loadFilters() {

            let filterResponse = await httpAPI({
                url: `api/eventmanagement/filter?collection=${this.collection}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.filterDataSource = filterResponse ? filterResponse.data.payload : null;
        },
        async loadData() {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let type = '';

            switch (this.collection)
            {
                case 'EventsList':
                    type = 'None.Meeting'; break; // нужен тайп для списка

                case 'EventsCalendar':
                    type = 'None.Meeting'; break; // нужен тайп для календаря

                default:
                    type = 'None.Meeting'; break;
            }

            let response = await httpAPI({
                url: `api/actions/collection?type=${ type }`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }
        },

    },
    async created() {
        await this.loadData();
    },
}
</script>